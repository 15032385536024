import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {map, Subject, takeUntil} from 'rxjs';
import {ServiceRegistrationService} from '../../../../core/service/service-registration.service';
import {
  EvaluationQuestion,
  EvaluationQuestionSection,
  EvaluationQuestionType,
  IEvaluation
} from '../../../../shared/model/evaluation-model';
import {IServiceRegistration} from '../../../../shared/model/service-registration.model';

@Component({
  selector: 'app-service-evaluation-improvement',
  templateUrl: './service-evaluation-improvement.component.html',
  styleUrls: ['./service-evaluation-improvement.component.scss']
})
export class ServiceEvaluationImprovementComponent implements OnInit, OnDestroy{
  @Input()
  public service!: IServiceRegistration;

  @Output()
  public evaluationComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  public improvementForm!: FormGroup;
  public evaluations: IEvaluation[] = [];
  private endSubscription$ = new Subject();

  public evaluationQuestions: EvaluationQuestion[] = [
    { id: 'SU_IMP_1', text: 'Suggestions', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.IMPROVEMENT, order: '1' },
    { id: 'SU_IMP_2', text: 'SuggestionText', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.IMPROVEMENT, order: '2' },
    { id: 'SU_IMP_3', text: 'Comments', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.IMPROVEMENT, order: '3' }
  ];

  constructor(private fb: UntypedFormBuilder, private serviceRegistrationService: ServiceRegistrationService) { }

  ngOnInit() {
    this.improvementForm = this.fb.group({
      Suggestions: [null , [Validators.required]],
      SuggestionText: [null],
      Comments: [null]
    })

    this.getEvaluations();

    this.improvementForm.valueChanges.pipe(takeUntil(this.endSubscription$)).subscribe(() => {
      this.evaluationComplete.emit(this.improvementForm.valid);
    });

    this.improvementForm.get('Suggestions')?.valueChanges.pipe(takeUntil(this.endSubscription$)).subscribe((newSuggestionsValue) => {
      const suggestionTextControl = this.improvementForm.get('SuggestionText');

      if (newSuggestionsValue === 'Yes') {
        suggestionTextControl?.setValidators(Validators.required);
      } else {
        suggestionTextControl?.setValidators(null);
      }

      suggestionTextControl?.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  public getEvaluations() {
    this.serviceRegistrationService.getEvaluations(this.service.id).pipe(
      map(evaluations => evaluations.filter(evaluation => evaluation.question.type === EvaluationQuestionType.SUPERVISOR && evaluation.question.section === EvaluationQuestionSection.IMPROVEMENT)))
      .subscribe(filteredEvals => {
        this.evaluations = filteredEvals;
        filteredEvals.forEach(evaluation => {
          if(evaluation.question.order === '1') {
            this.improvementForm.get('Suggestions')?.setValue(evaluation.response);
          } else if (evaluation.question.order === '2') {
            this.improvementForm.get('SuggestionText')?.setValue(evaluation.response);
          } else if (evaluation.question.order === '3') {
            this.improvementForm.get('Comments')?.setValue(evaluation.response);
          }
        })
      })
  }

  public submitEvaluations() {
    if (this.evaluations.length > 0) {
      const updatedEvaluations: IEvaluation[] = [];

      Object.keys(this.improvementForm.controls).forEach((question, index) => {
        const value = this.improvementForm.get(question)?.value;

        if(value !== null && value !== undefined && value !== ''){
          const existingEvaluation = this.evaluations.find(evaluation => evaluation.question.order === this.evaluationQuestions[index].order);

          if (existingEvaluation) {
            existingEvaluation.response = value;
            updatedEvaluations.push(existingEvaluation);
          } else if (question == 'Comments') {
            this.addNewComment(question, index, value);
          }
        }
      });

      if(updatedEvaluations.length > 0) {
        updatedEvaluations.forEach(evaluation => {
          this.serviceRegistrationService.updateEvaluations(this.service.id, evaluation).subscribe();
        });
      }
    } else {
      const newEvaluations: Partial<IEvaluation>[] = Object.keys(this.improvementForm.controls)
        .filter(question => {
          const control = this.improvementForm.get(question);
          const value = control?.value;

          return !(value === null || value === undefined);
        })
        .map((question, index) => {
          const value = this.improvementForm.get(question)?.value;

          return {
            serviceId: this.service.id,
            response: value,
            question: {
              id: this.evaluationQuestions[index].id,
              order: this.evaluationQuestions[index].order,
              section: EvaluationQuestionSection.IMPROVEMENT,
              text: question,
              type: EvaluationQuestionType.SUPERVISOR
            }
          };
        });

      this.serviceRegistrationService.addEvaluations(this.service.id, newEvaluations).subscribe();
    }
  }

  public addNewComment(question: string, index:number, value:string) {
    let newEvaluation: Partial<IEvaluation> = {
      serviceId: this.service.id,
      response: value,
      question: {
        id: this.evaluationQuestions[index].id,
        order: this.evaluationQuestions[index].order,
        section: EvaluationQuestionSection.IMPROVEMENT,
        text: question,
        type: EvaluationQuestionType.SUPERVISOR
      }
    }
    let newEvaluations: Partial<IEvaluation>[] = [];
    newEvaluations.push(newEvaluation);

    this.serviceRegistrationService.addEvaluations(this.service.id, newEvaluations).subscribe();
  }

}
