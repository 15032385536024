<form [formGroup]="improvementForm" class="improvementForm f-col">
  <div class="question f-col">
    <div class="medium-regular-text">Are there any areas of improvement you would suggest for the student?</div>
    <div class="radio-group f-col">
      <div class="custom-input" *ngFor="let option of ['No', 'Yes']" [ngClass]="option === improvementForm.get('Suggestions')?.value ? 'active' : ''" #suggestions>
        <input id="{{option}}" type="radio" [formControlName]="'Suggestions'" [value]="option">
        <label for="{{option}}" class="small-reg-text">{{option}}</label>
      </div>
    </div>
    <div *ngIf="improvementForm.get('Suggestions')?.value === 'Yes'" class="suggestion-wrapper">
      <label for="improvement" class="small-reg-text">What areas of improvement would you suggest?</label>
      <input class="custom-input" type="text" id="improvement" [formControlName]="'SuggestionText'" placeholder="Type your suggestions...">
    </div>
  </div>
  <div class="question f-col">
    <div class="input-wrapper">
      <label for="commentsTextArea" class="medium-medium-text" id="commentsLabel">Do you have any other comments for the student?
        <span class="gray">(Optional)</span>
      </label>
      <textarea id="commentsTextArea" class="custom-input text-area" [formControlName]="'Comments'" placeholder="Type your comments..." aria-labelledby="commentsLabel"></textarea>
    </div>
  </div>
</form>
