<div class="medium-bold-text">How would you rate the following?</div>
<form [formGroup]="experienceForm">
  <div class="rating-list f-col" *ngIf="hasRetrievedEvals">
    <div class="rating-section f-col">
      <div class="rating-row f-row">
        <div class="input-title small-medium-text">Supervisor ({{opportunity.supervisorName}})</div>
        <app-rating-widget [name]="'supervisor'" [initialRating]="getEvaluationRating('ST_EXP_1')" (currentRatingEvent)="setCurrentRating($event, 'supervisor')"></app-rating-widget>
      </div>
      <label for="input" class="input-label f-row">
        <span class="input-title x-small-reg-text">Comments*</span>
        <span class="x-small-reg-text gray">{{input.value.length}}/500 Chars</span>
      </label>
      <textarea class="custom-input" [formControlName]="'ST_EXP_2'" placeholder="Type your response..." id="input" maxlength="500" #input></textarea>
    </div>
    <div class="rating-section f-col">
      <div class="rating-row f-row">
        <div class="input-title small-medium-text">Organization ({{opportunity.organizationName}})</div>
        <app-rating-widget [name]="'organization'" [initialRating]="getEvaluationRating('ST_EXP_3')" (currentRatingEvent)="setCurrentRating($event, 'organization')"></app-rating-widget>
      </div>
    </div>
  </div>
</form>
