<div class="likert-table f-col">
  <div class="title-and-desc f-col">
    <div class="medium-bold-text">Future Recommendation</div>
    <div class="small-reg-text">If asked by the student, how likely are you to provide the following?</div>
  </div>
  <table class="recommendation-table" [formGroup]="futureForm">
    <legend class="f-col">
      <div class="legend-row xx-small-medium-text">
        <div>Very Unlikely</div>
        <div>Somewhat Unlikely</div>
        <div>Neutral</div>
        <div>Somewhat Likely</div>
        <div>Very Likely</div>
      </div>
    </legend>
    <tr *ngFor="let question of evaluationQuestions">
      <div class="small-bold-text">{{question.text}}</div>
      <ul class="f-row">
        <li class="custom-radio-input" *ngFor="let value of ['Very Unlikely', 'Somewhat Unlikely', 'Neutral', 'Somewhat Likely', 'Very Likely']">
          <input type="radio" [formControlName]="question.text" [value]="value">
        </li>
      </ul>
    </tr>
  </table>
</div>
