<form class="likert-table f-col" [formGroup]="reflectionForm">
  <div class="title-and-desc f-col">
    <div class="medium-bold-text">Personal Reflection</div>
    <div class="small-reg-text">LU Serve's CSER program strives to develop student along four core values: Character, Community, Curriculum, and Career. We want students to serve with organizations and supervisors that exemplify these values.</div>
    <div class="small-reg-text">Please rate your agreement with the following:</div>
  </div>

  <div *ngIf="!isMobile">
    <legend class="f-col">
      <div class="legend-row xx-small-medium-text">
        <div>Strongly Disagree</div>
        <div>Somewhat Disagree</div>
        <div>Neither Agree or Disagree</div>
        <div>Somewhat Agree</div>
        <div>Strongly Agree</div>
      </div>
    </legend>
    <tr *ngFor="let question of evaluationQuestions">
      <div class="small-bold-text">{{ question.text }}</div>
      <ul class="f-row">
        <li class="custom-radio-input" *ngFor="let value of ['Strongly Disagree', 'Disagree', 'Neither Agree nor Disagree', 'Agree', 'Strongly Agree']">
          <input type="radio" [formControlName]="question.id" [value]="value"/>
        </li>
      </ul>
    </tr>
  </div>

  <div *ngIf="isMobile" class="mobile-form">
    <div *ngFor="let question of evaluationQuestions" class="reflection-form-question">
      <div class="reflection-question-title x-small-bold-text">{{question.text}}</div>
      <div class="reflection-responses f-col">
        <div *ngFor="let value of ['Strongly Disagree', 'Disagree', 'Neither Agree nor Disagree', 'Agree', 'Strongly Agree']" class="reflection-answer f-row small-reg-text custom-radio-input">
          <input type="radio" [formControlName]="question.id" [value]="value" />
          <div class="reflection-rating">{{value}}</div>
        </div>
      </div>
    </div>
  </div>
</form>
