import { Component } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {emailPatternValidator, forbiddenCharactersValidator, getEmailErrorMessage} from '../../../../shared/function/form-validators';

@Component({
  selector: 'app-past-evaluation-supervisor',
  templateUrl: './past-evaluation-supervisor.component.html',
  styleUrls: ['./past-evaluation-supervisor.component.scss']
})
export class PastEvaluationSupervisorComponent {
  public form: UntypedFormGroup = new UntypedFormGroup({});

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, [Validators.required, Validators.minLength(10)]],
      email: [null, [Validators.required, emailPatternValidator(), forbiddenCharactersValidator()]],
      phone: [null, Validators.required],
    });
  }

  setStyle(value: string) {
    if(this.form.get(value)?.invalid && (this.form.get(value)?.dirty || this.form.get(value)?.touched)) {
      return "custom-input-error"
    } else if(this.form.get(value)?.valid) {
      return "custom-input-success"
    }

    return undefined;
  }

  public getEmailError() {
    return getEmailErrorMessage(this.form.controls?.['email']);
  }
}
