<div class="likert-table f-col">
  <div class="title-and-desc f-col">
    <div class="medium-bold-text">Personal Reflection</div>
    <div class="small-reg-text">LU Serve's CSER program strives to develop student along four core values: Character, Community, Curriculum, and Career. We want students to serve with organizations and supervisors that exemplify these values.</div>
    <div class="small-reg-text">Please rate your agreement with the following:</div>
  </div>

  <table class="past-reflection-table" [formGroup]="form">
    <legend class="f-col">
      <div class="legend-row xx-small-medium-text">
        <div>Strongly Disagree</div>
        <div>Somewhat Disagree</div>
        <div>Neither Agree or Disagree</div>
        <div>Somewhat Agree</div>
        <div>Strongly Agree</div>
      </div>
    </legend>
    <tr>
      <div class="small-bold-text">This CSER challenged me to develop stronger moral/spiritual character.</div>
      <ul class="f-row">
        <li class="custom-radio-input"><input type="radio" name="moral" value="1" /></li>
        <li class="custom-radio-input"><input type="radio" name="moral" value="2" /></li>
        <li class="custom-radio-input"><input type="radio" name="moral" value="3" /></li>
        <li class="custom-radio-input"><input type="radio" name="moral" value="4" /></li>
        <li class="custom-radio-input"><input type="radio" name="moral" value="5" /></li>
      </ul>
    </tr>
    <tr>
      <div class="small-bold-text">This CSER allowed me to make an important impact in the community.</div>
      <ul class="f-row">
        <li class="custom-radio-input"><input type="radio" name="impact" value="1" /></li>
        <li class="custom-radio-input"><input type="radio" name="impact" value="2" /></li>
        <li class="custom-radio-input"><input type="radio" name="impact" value="3" /></li>
        <li class="custom-radio-input"><input type="radio" name="impact" value="4" /></li>
        <li class="custom-radio-input"><input type="radio" name="impact" value="5" /></li>
      </ul>
    </tr>
    <tr>
      <div class="small-bold-text">This CSER is directly related to my academic major or minor.</div>
      <ul class="f-row">
        <li class="custom-radio-input"><input type="radio" name="academic" value="1" /></li>
        <li class="custom-radio-input"><input type="radio" name="academic" value="2" /></li>
        <li class="custom-radio-input"><input type="radio" name="academic" value="3" /></li>
        <li class="custom-radio-input"><input type="radio" name="academic" value="4" /></li>
        <li class="custom-radio-input"><input type="radio" name="academic" value="5" /></li>
      </ul>
    </tr>
    <tr>
      <div class="small-bold-text">Through this CSER, I gained skills that will help me in my future career.</div>
      <ul class="f-row">
        <li class="custom-radio-input"><input type="radio" name="skills" value="1" /></li>
        <li class="custom-radio-input"><input type="radio" name="skills" value="2" /></li>
        <li class="custom-radio-input"><input type="radio" name="skills" value="3" /></li>
        <li class="custom-radio-input"><input type="radio" name="skills" value="4" /></li>
        <li class="custom-radio-input"><input type="radio" name="skills" value="5" /></li>
      </ul>
    </tr>
  </table>
</div>
