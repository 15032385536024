  <div class="title-and-desc f-col">
    <div class="medium-bold-text">Log all time worked for this opportunity</div>
    <div class="small-reg-text">Please record all instances of volunteering with the amount of time worked, date you worked and a brief description of the work done that day.</div>
  </div>
  <app-log-time-widget></app-log-time-widget>
  <div class="qualify-note">
    <app-icon icon="check-circle" class="check-icon"></app-icon>
    <div class="note-text f-col">
      <div class="medium-bold-text">You qualify for 1 credit (Minimum 20 Hours)</div>
      <div class="small-reg-text">In order to qualify for 2 credits, you need to log a minimum of 40 hours.</div>
    </div>
  </div>
