import {NgModule} from "@angular/core";
import {MatStepperModule} from '@angular/material/stepper';
import {SharedModule} from "../../shared/shared.module";
import {EvaluationRoutingModule} from './evaluation-routing.module';
import {
  EvaluationExperienceComponent
} from './evaluation-stepper-pages/evaluation-experience/evaluation-experience.component';
import {
  EvaluationOpportunityComponent
} from './evaluation-stepper-pages/evaluation-opportunity/evaluation-opportunity.component';
import {
  EvaluationReflectionComponent
} from './evaluation-stepper-pages/evaluation-reflection/evaluation-reflection.component';
import {EvaluationReviewComponent} from './evaluation-stepper-pages/evaluation-review/evaluation-review.component';
import {EvaluationFeedbackComponent} from './evaluation-feedback/evaluation-feedback.component';
import {
  PastEvaluationOrganizationComponent
} from './past-evaluation-stepper-pages/past-evaluation-organization/past-evaluation-organization.component';
import {
  PastEvaluationSupervisorComponent
} from './past-evaluation-stepper-pages/past-evaluation-supervisor/past-evaluation-supervisor.component';
import {
  PastEvaluationReflectionComponent
} from './past-evaluation-stepper-pages/past-evaluation-reflection/past-evaluation-reflection.component';
import {
  PastEvaluationExperienceComponent
} from './past-evaluation-stepper-pages/past-evaluation-experience/past-evaluation-experience.component';
import {
  PastEvaluationReviewComponent
} from './past-evaluation-stepper-pages/past-evaluation-review/past-evaluation-review.component';
import {EvaluationStepperComponent} from './evaluation-stepper-pages/evaluation-stepper.component';
import {PastEvaluationStepperComponent} from './past-evaluation-stepper-pages/past-evaluation-stepper.component';
import {
  ServiceEvaluationStepperComponent
} from './service-evaluation-stepper-pages/service-evaluation-stepper.component';
import {
  ServiceEvaluationPerformanceComponent
} from './service-evaluation-stepper-pages/service-evaluation-performance/service-evaluation-performance.component';
import {
  ServiceEvaluationFutureComponent
} from './service-evaluation-stepper-pages/service-evaluation-future/service-evaluation-future.component';
import {
  ServiceEvaluationImprovementComponent
} from './service-evaluation-stepper-pages/service-evaluation-improvement/service-evaluation-improvement.component';
import {
  ServiceEvaluationGradeComponent
} from './service-evaluation-stepper-pages/service-evaluation-grade/service-evaluation-grade.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {
  ServiceEvaluationHoursComponent
} from "./service-evaluation-stepper-pages/service-evaluation-hours/service-evaluation-hours.component";

@NgModule({
  imports: [
    SharedModule,
    EvaluationRoutingModule,
    MatStepperModule,
    MatTooltipModule,
  ],
  exports: [
    EvaluationReflectionComponent,
    EvaluationOpportunityComponent,
    EvaluationExperienceComponent,
    EvaluationReviewComponent,
    EvaluationFeedbackComponent,
    EvaluationStepperComponent,
    PastEvaluationStepperComponent,
    PastEvaluationOrganizationComponent,
    PastEvaluationSupervisorComponent,
    PastEvaluationReflectionComponent,
    PastEvaluationExperienceComponent,
    PastEvaluationReviewComponent,
  ],
  declarations: [
    EvaluationReflectionComponent,
    EvaluationOpportunityComponent,
    EvaluationExperienceComponent,
    EvaluationReviewComponent,
    EvaluationFeedbackComponent,
    PastEvaluationOrganizationComponent,
    PastEvaluationSupervisorComponent,
    PastEvaluationReflectionComponent,
    PastEvaluationExperienceComponent,
    PastEvaluationReviewComponent,
    EvaluationStepperComponent,
    PastEvaluationStepperComponent,
    ServiceEvaluationStepperComponent,
    ServiceEvaluationHoursComponent,
    ServiceEvaluationPerformanceComponent,
    ServiceEvaluationFutureComponent,
    ServiceEvaluationImprovementComponent,
    ServiceEvaluationGradeComponent
  ]
})
export class EvaluationModule {}
