import { createAction, props } from "@ngrx/store";
import { IServiceRegistration } from "../../../shared/model/service-registration.model";
import { HttpErrorResponse } from "@angular/common/http";

export const attemptCSERRegisterAction = createAction(
    '[Register] Attempt Registration Action',
    props<{ serviceRegistration: IServiceRegistration }>()
);

export const attemptCSERRegisterSuccessAction = createAction(
    '[Register] Attempt Registration Success Action',
    props<{ registrationMessage: string, registration: IServiceRegistration }>()
);

export const attemptCSERRegisterFailureAction = createAction(
    '[Register] Attempt Registration Failure Action',
    props<{ error: HttpErrorResponse }>()
);

export const clearRegistrationDataAction = createAction(
    '[Register] Clear Registration Data'
)