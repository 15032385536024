import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {map, Subject, takeUntil} from 'rxjs';
import {ServiceRegistrationService} from '../../../../core/service/service-registration.service';
import {EvaluationFormControls} from '../../../../shared/model/evaluation-form-control.model';
import {
  EvaluationQuestion,
  EvaluationQuestionSection,
  EvaluationQuestionType,
  IEvaluation
} from '../../../../shared/model/evaluation-model';
import {IServiceRegistration} from '../../../../shared/model/service-registration.model';

@Component({
  selector: 'app-service-evaluation-performance',
  templateUrl: './service-evaluation-performance.component.html',
  styleUrls: ['./service-evaluation-performance.component.scss']
})
export class ServiceEvaluationPerformanceComponent implements OnInit, OnDestroy{
  @Input()
  public service!: IServiceRegistration;

  @Output()
  public evaluationComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  public performanceForm!: FormGroup;
  public evaluations: IEvaluation[] = [];
  public formControls: EvaluationFormControls = {};
  private endSubscription$ = new Subject();

  public evaluationQuestions: EvaluationQuestion[] = [
    { id: 'SU_PER_1', text: 'Punctuality', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.PERFORMANCE, order: '1' },
    { id: 'SU_PER_2', text: 'Communication', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.PERFORMANCE, order: '2' },
    { id: 'SU_PER_3', text: 'Problem Solving', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.PERFORMANCE, order: '3' },
    { id: 'SU_PER_4', text: 'Work Ethic', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.PERFORMANCE, order: '4' },
    { id: 'SU_PER_5', text: 'Testimony/Character', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.PERFORMANCE, order: '5' }
  ];

  constructor(private fb: FormBuilder, private serviceRegistrationService: ServiceRegistrationService) {}

  ngOnInit() {
    this.evaluationQuestions.forEach(question => {
      this.formControls[question.text] = [null, [Validators.required]];
    });

    this.performanceForm = this.fb.group(this.formControls);

    this.getEvaluations();

    this.performanceForm.valueChanges.pipe(takeUntil(this.endSubscription$)).subscribe(() => {
      this.evaluationComplete.emit(this.performanceForm.valid);
    })
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  public getEvaluations() {
    this.serviceRegistrationService.getEvaluations(this.service.id).pipe(
      map(evaluations => evaluations.filter(evaluation => evaluation.question.type === EvaluationQuestionType.SUPERVISOR && evaluation.question.section === EvaluationQuestionSection.PERFORMANCE)))
      .subscribe(filteredEvals => {
        this.evaluations = filteredEvals;

        filteredEvals.forEach(evaluation => {
          if(evaluation.question.order === '1') {
            this.performanceForm.get('Punctuality')?.setValue(evaluation.response);
          } else if (evaluation.question.order === '2') {
            this.performanceForm.get('Communication')?.setValue(evaluation.response);
          } else if (evaluation.question.order === '3') {
            this.performanceForm.get('Problem Solving')?.setValue(evaluation.response);
          } else if (evaluation.question.order === '4') {
            this.performanceForm.get('Work Ethic')?.setValue(evaluation.response);
          } else if (evaluation.question.order === '5') {
            this.performanceForm.get('Testimony/Character')?.setValue(evaluation.response);
          }
        })
    })
  }

  public submitEvaluations() {
    if (this.evaluations.length > 0) {
      const updatedEvaluations: IEvaluation[] = [];

      Object.keys(this.performanceForm.controls).forEach((question, index) => {
        const value = this.performanceForm.get(question)?.value;
        const existingEvaluation = this.evaluations.find(evaluation => evaluation.question.order === this.evaluationQuestions[index].order);

        if (existingEvaluation) {
          existingEvaluation.response = value;
          updatedEvaluations.push(existingEvaluation);
        }
      });

      updatedEvaluations.forEach(evaluation => {
        this.serviceRegistrationService.updateEvaluations(this.service.id, evaluation).subscribe();
      });
    } else {
      const newEvaluations: Partial<IEvaluation>[] = Object.keys(this.performanceForm.controls).map((question, index) => {
        const value = this.performanceForm.get(question)?.value;

        return {
          serviceId: this.service.id,
          response: value,
          question: {
            id: this.evaluationQuestions[index].id,
            order: this.evaluationQuestions[index].order,
            section: EvaluationQuestionSection.PERFORMANCE,
            text: question,
            type: EvaluationQuestionType.SUPERVISOR
          }
        };
      });

      this.serviceRegistrationService.addEvaluations(this.service.id, newEvaluations).subscribe();
    }
  }
}
