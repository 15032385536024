<div class="medium-bold-text">Who was your supervisor?</div>
<form class="supervisor-form f-col" [formGroup]="form">
  <div class="input-wrapper">
    <label for="nameInput" class="x-small-medium-text" id="nameLabel">Full Name</label>
    <input id="nameInput" [ngClass]="setStyle('name')" class="custom-input" matInput
           maxlength="15" formControlName="name" data-cy="name" aria-labelledby="nameLabel">
  </div>
  <div class="input-wrapper">
    <label for="emailInput" class="x-small-medium-text" id="emailLabel">Email</label>
    <input id="emailInput" [ngClass]="setStyle('email')" class="custom-input" matInput
           formControlName="email" data-cy="email" aria-labelledby="emailLabel">
    <mat-error *ngIf="form.controls['email'].invalid">
      {{ getEmailError() }}
    </mat-error>
  </div>
  <div class="input-wrapper">
    <label for="phoneInput" class="x-small-medium-text" id="phoneLabel">Student's Phone Number</label>
    <input id="phoneInput" [ngClass]="setStyle('phone')" class="custom-input" matInput placeholder="(123) 456-7890"
           formControlName="phone" data-cy="phone" aria-labelledby="phoneLabel">
  </div>
</form>
