<div class="alert f-row">
  <app-icon icon="alert-circle" class="alert-icon"></app-icon>
  <div class="small-bold-text">You will be charged a $10 fee on your account for past term registrations</div>
</div>
<form class="org-details f-col" [formGroup]="form">
  <div class="medium-bold-text">What organization did you serve at?</div>
  <div class="input-wrapper">
    <label for="nameInput" class="small-reg-text" id="nameLabel">Organization Name</label>
    <input id="nameInput" [ngClass]="setStyle('name')" class="custom-input" matInput
           maxlength="15" formControlName="name" data-cy="name" aria-labelledby="nameLabel">
  </div>
  <div class="input-wrapper">
    <label for="websiteInput" class="small-reg-text" id="websiteLabel">Organization Website</label>
    <input id="websiteInput" [ngClass]="setStyle('website')" class="custom-input" matInput
           formControlName="website" data-cy="website" aria-labelledby="websiteLabel">
  </div>

  <fieldset class="type-field f-col">
    <legend class="medium-bold-text">What type of organization is this?</legend>
    <div class="custom-input custom-radio-input" [ngClass]="orgType === 'nonprofit' ? 'radio-active' : ''">
      <input type="radio" id="nonprofit" name="org-type" value="nonprofit" [(ngModel)]="orgType" [ngModelOptions]="{standalone: true}" >
      <label for="nonProfit" class="small-reg-text" for="nonprofit">501(c)(3) Nonprofit</label>
    </div>
    <div class="custom-input custom-radio-input" [ngClass]="orgType === 'church' ? 'radio-active' : ''">
      <input type="radio" id="church" name="org-type" value="church" [(ngModel)]="orgType" [ngModelOptions]="{standalone: true}" >
      <label for="church" class="small-reg-text" for="church">Church</label>
    </div>
    <div class="custom-input custom-radio-input"  [ngClass]="orgType === 'civic' ? 'radio-active' : ''">
    <input type="radio" id="civic" name="org-type" value="civic" [(ngModel)]="orgType" [ngModelOptions]="{standalone: true}" >
    <label for="civic" class="small-reg-text" for="civic">Civic Organization</label>
    </div>
    <div class="custom-input custom-radio-input"  [ngClass]="orgType === 'other' ? 'radio-active' : ''">
    <input type="radio" id="other" name="org-type" value="other" [(ngModel)]="orgType" [ngModelOptions]="{standalone: true}" >
    <label for="other" class="small-reg-text" for="other">Other/For-Profit</label>
  </div>
  </fieldset>
</form>
