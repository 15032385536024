<div class="text f-col">
  <div class="large-bold-text">Thank you for your feedback!</div>
  <div class="small-reg-text">Once your evaluation is approved, you will receive credit on your account.</div>
</div>
<div class="actions f-col">
  <div class="find-opportunity f-col">
    <div class="large-bold-text">Ready to register for next semester?</div>
    <button class="custom-button-large-secondary" [routerLink]="['/', 'search-opportunity']">
      <app-icon icon="search" class="small-icon"></app-icon>
     <div class="small-medium-text">
       Find an Opportunity
     </div>
    </button>
  </div>
</div>
