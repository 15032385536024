<div class="header-wrapper f-row">
  <div class="header-info f-col">
    <div class="x-large-x-bold-text">Past Term Registration</div>
    <div class="small-medium-text">For previously completed opportunities</div>
  </div>
  <mat-stepper class="stepper" [linear]="true" labelPosition="bottom" color="primary" #stepper>
    <mat-step label="Organization"></mat-step>
    <mat-step label="Supervisor"></mat-step>
    <mat-step label="Reflection"></mat-step>
    <mat-step label="Experience"></mat-step>
    <mat-step label="Review"></mat-step>
  </mat-stepper>
</div>

<div [ngSwitch]="stepper.selected?.label" class="switch-page f-col">
  <div *ngSwitchCase="'Organization'" class="f-col">
    <app-past-evaluation-organization></app-past-evaluation-organization>
  </div>
  <div *ngSwitchCase="'Supervisor'" class="f-col">
    <app-past-evaluation-supervisor></app-past-evaluation-supervisor>
  </div>
  <div *ngSwitchCase="'Reflection'" class="f-col">
    <app-past-evaluation-reflection></app-past-evaluation-reflection>
  </div>
  <div *ngSwitchCase="'Experience'" class="f-col">
    <app-past-evaluation-experience></app-past-evaluation-experience>
  </div>
  <div *ngSwitchCase="'Review'" class="f-col">
    <app-past-evaluation-review></app-past-evaluation-review>
  </div>
</div>

<div class="buttons-wrapper f-row">
  <button *ngIf="stepper.selected?.label != 'Organization'" class="custom-button-large-secondary" (click)="stepper.previous()">
    <app-icon icon="arrow-left" class="left-icon"></app-icon>
    Previous
  </button>
  <div *ngIf="stepper.selected?.label == 'Organization'"></div>
  <button class="custom-button-large-primary" *ngIf="stepper.selected?.label != 'Review'" (click)="stepper.next()">
    Continue
    <app-icon icon="arrow-right" class="right-icon"></app-icon>
  </button>
  <button class="custom-button-large-primary" *ngIf="stepper.selected?.label == 'Review'" (click)="submitForm()">
    Submit
  </button>
</div>
