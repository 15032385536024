import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {filter, Subject} from 'rxjs';
import {OpportunityService} from '../../../core/service/opportunity.service';
import {ServiceRegistrationService} from '../../../core/service/service-registration.service';
import {selectServiceId,} from '../../../core/store/router/router.selectors';
import {IOpportunity} from '../../../shared/model/opportunity.model';
import {IServiceRegistration, ServiceEvalStatus} from '../../../shared/model/service-registration.model';
import {EvaluationExperienceComponent} from './evaluation-experience/evaluation-experience.component';
import {EvaluationOpportunityComponent} from './evaluation-opportunity/evaluation-opportunity.component';
import {EvaluationReflectionComponent} from './evaluation-reflection/evaluation-reflection.component';

@Component({
  selector: 'app-evaluation-stepper',
  templateUrl: './evaluation-stepper.component.html',
  styleUrls: ['./evaluation-stepper.component.scss']
})
export class EvaluationStepperComponent implements OnInit, OnDestroy {
  public oppId!: string;
  public opportunity!: IOpportunity;
  public serviceRegistration!: IServiceRegistration;

  public reflectionComplete: boolean = false;
  public opportunityComplete: boolean = false;
  public experienceComplete: boolean = false;
  public reviewComplete: boolean = false;

  @ViewChild('reflectionComponent') reflectionComponent!: EvaluationReflectionComponent;
  @ViewChild('opportunityComponent') opportunityComponent!: EvaluationOpportunityComponent;
  @ViewChild('experienceComponent') experienceComponent!: EvaluationExperienceComponent;

  private endSubscription$ = new Subject();

  constructor(
    private router: Router,
    private opportunityService: OpportunityService,
    private store: Store,
    private serviceRegService: ServiceRegistrationService) {}

  ngOnInit() {
    this.store.pipe(select(selectServiceId), filter(Boolean)).subscribe(serviceId => {
      this.serviceRegService.getServiceRegistration(serviceId).subscribe(serviceReg => {
        this.serviceRegistration = serviceReg;

        this.opportunityService.getOpportunity(serviceReg.oppId).subscribe(opp => this.opportunity = opp)
      })
    })
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  public getServiceRegistration() {
    this.serviceRegService.getServiceRegistration(this.serviceRegistration.id).subscribe(serviceReg => {
      this.serviceRegistration = serviceReg;
      this.evalEvent(this.serviceRegistration.hoursServed >= 20, 'review');
    })
  }

  public submitEvaluation() {
    if(this.opportunityComplete && this.experienceComplete && this.reflectionComplete && this.serviceRegistration.hoursServed >= 20) {
      let updatedService: IServiceRegistration = {
        ...this.serviceRegistration,
        studentEvalStatus: ServiceEvalStatus.COMPLETE
      }

      this.serviceRegService.updateServiceRegistration(updatedService).subscribe(() => {
        this.router.navigate(['/', 'evaluation', 'feedback']);
      })
    }
  }

  public canProceedStepper(index: number) {
    switch (index) {
      case 0:
        return this.reflectionComplete;
      case 1:
        return this.opportunityComplete;
      case 2:
        return this.experienceComplete;
      case 3:
        return this.reviewComplete;
      default:
        return true;
    }
  }

  public evalEvent(event: boolean, evalName: string) {
    if(evalName === 'reflection') {
      this.reflectionComplete = event;
    } else if(evalName === 'opportunity') {
      this.opportunityComplete = event;
    } else if(evalName === 'experience') {
      this.experienceComplete = event;
    } else if(evalName === 'review') {
      this.reviewComplete = this.serviceRegistration.hoursServed >= 20;
    }
  }

  public submitAnswers(index: number) {
    switch (index) {
      case 1:
        this.reflectionComponent.submitEvaluations();
        break;
      case 2:
        this.opportunityComponent.submitEvaluations();
        break;
      case 3:
        this.experienceComponent.submitEvaluations();
        break;
    }
  }
}
