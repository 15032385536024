<div class="medium-bold-text">How would you rate the following?</div>
<form [formGroup]="form">
  <div class="rating-list f-col">
    <div class="rating-section f-col">
      <div class="rating-row f-row">
        <div class="small-medium-text">Supervisor (Chris Wallace)</div>
        <app-rating-widget [name]="'supervisor'" (currentRatingEvent)="setCurrentRating($event)"></app-rating-widget>
      </div>
      <label for="input" class="input-label f-row">
        <span class="x-small-reg-text">Comments*</span>
        <span class="x-small-reg-text gray">{{comments.value.length}}/500 Chars</span>
      </label>
      <textarea class="custom-input" name="comments" placeholder="Type your response..." id="input" maxlength="500" #comments></textarea>
    </div>
    <div class="rating-section f-col">
      <div class="rating-row f-row">
        <div class="small-medium-text">Organization (Office of Spiritual Development</div>
        <app-rating-widget [name]="'organization'" (currentRatingEvent)="setCurrentRating($event)"></app-rating-widget>
      </div>
    </div>
    <div class="rating-section-bottom f-col">
      <div class="rating-row f-row">
        <div class="small-medium-text">Overall Experience</div>
        <app-rating-widget [name]="'overall'" (currentRatingEvent)="setCurrentRating($event)"></app-rating-widget>
      </div>
    </div>

  </div>
</form>
