import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {map, Subject, take, takeUntil} from 'rxjs';
import {ServiceRegistrationService} from '../../../../core/service/service-registration.service';
import {EvaluationFormControls} from '../../../../shared/model/evaluation-form-control.model';
import {
  EvaluationQuestion,
  EvaluationQuestionSection,
  EvaluationQuestionType,
  IEvaluation
} from '../../../../shared/model/evaluation-model';
import {IServiceRegistration} from '../../../../shared/model/service-registration.model';

@Component({
  selector: 'app-evaluation-opportunity',
  templateUrl: './evaluation-opportunity.component.html',
  styleUrls: ['./evaluation-opportunity.component.scss']
})
export class EvaluationOpportunityComponent implements OnInit, OnDestroy{
  @Input()
  public service!: IServiceRegistration;

  @Output()
  public evaluationComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  public opportunityForm!: FormGroup;
  public formControls: EvaluationFormControls = {};
  public evaluations: IEvaluation[] = [];
  private endSubscription$ = new Subject();

  public evaluationQuestions: EvaluationQuestion[] = [
    { id: 'ST_OPP_1', text: 'I was interested in learning something new or different', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.OPPORTUNITY, order: '1' },
    { id: 'ST_OPP_2', text: 'I saw a need and I filled it', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.OPPORTUNITY, order: '2' },
    { id: 'ST_OPP_3', text: 'It was recommended by a friend', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.OPPORTUNITY, order: '3' },
    { id: 'ST_OPP_4', text: 'It was conveniently located', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.OPPORTUNITY, order: '4' },
    { id: 'ST_OPP_5', text: 'I found it on the website', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.OPPORTUNITY, order: '5' },
    { id: 'ST_OPP_6', text: 'I wanted to serve under this supervisor', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.OPPORTUNITY, order: '6' },
    { id: 'ST_OPP_7', text: 'It relates to my major or minor', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.OPPORTUNITY, order: '7' },
    { id: 'ST_OPP_8', text: 'I was able to complete my hours in a short period of time', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.OPPORTUNITY, order: '8' },
    { id: 'ST_OPP_9', text: 'It relates to my future career interests', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.OPPORTUNITY, order: '9' },
    { id: 'ST_OPP_10', text: 'It was fun', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.OPPORTUNITY, order: '10' },
    { id: 'ST_OPP_11', text: 'I am passionate about this area of service', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.OPPORTUNITY, order: '11' },
    { id: 'ST_OPP_12', text: 'Other:', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.OPPORTUNITY, order: '12' },
    { id: 'ST_OPP_13', text: '', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.OPPORTUNITY, order: '13' },
  ];

  constructor(private fb: UntypedFormBuilder, private serviceRegistrationService: ServiceRegistrationService){}

  ngOnInit(): void {
    this.evaluationQuestions.forEach(question => {
      if(question.id !== 'ST_OPP_13') {
        this.formControls[question.id] = [false, []];
      } else {
        this.formControls['ST_OPP_13'] = [null, []];
      }
    });

    this.opportunityForm = this.fb.group(this.formControls);

    this.getEvaluations();

    this.opportunityForm.valueChanges.pipe(takeUntil(this.endSubscription$)).subscribe(() => {
      this.evaluationComplete.emit(this.opportunityForm.valid);
    })

    this.opportunityForm.get('ST_OPP_12')?.valueChanges.pipe(takeUntil(this.endSubscription$)).subscribe((newSuggestionsValue) => {
      const suggestionTextControl = this.opportunityForm.get('ST_OPP_13');

      if (newSuggestionsValue === true) {
        suggestionTextControl?.setValidators(Validators.required);
      } else {
        suggestionTextControl?.setValidators(null);
      }

      suggestionTextControl?.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  public getEvaluations() {
    this.serviceRegistrationService.getEvaluations(this.service.id).pipe(
      map(evaluations => evaluations.filter(evaluation => ((evaluation.question.type === EvaluationQuestionType.STUDENT) && (evaluation.question.section === EvaluationQuestionSection.OPPORTUNITY)))))
      .subscribe(filteredEvals => {
        this.evaluations = filteredEvals;

        filteredEvals.forEach(evaluation => {
          if(evaluation.question.id !== 'ST_OPP_13'){
            this.opportunityForm.get(evaluation.question.id)?.setValue(evaluation.response === 'true');
          } else {
            this.opportunityForm.get(evaluation.question.id)?.setValue(evaluation.response);
          }
        })
      })
  }

  public submitEvaluations() {
    if (this.evaluations.length > 0) {
      const updatedEvaluations: IEvaluation[] = [];
      const newEvaluations: Partial<IEvaluation>[] = [];

      Object.keys(this.opportunityForm.controls).forEach((question, index) => {
        const value = this.opportunityForm.get(question)?.value;

        if(value !== null && value !== undefined){
          const questionUsed = this.evaluationQuestions.find(evalQuestion => evalQuestion.id === question)!;
          const existingEvaluation = this.evaluations.find(evaluation => evaluation.question.order === questionUsed.order);

          //If the evaluation already exists, then it should check and see if the answer has changed, if so, add to updatedEval array. Otherwise, ignore.
          if (existingEvaluation) {
            if(existingEvaluation.response !== value.toString()) {
              updatedEvaluations.push({
                ...existingEvaluation,
                response: value.toString()
              });
            } else {
              return;
            }
          } else if (value !== false ) { //If evaluation does not exist, then add it if it has been selected, or has a value (question13)
            let newEval: Partial<IEvaluation> = {
              serviceId: this.service.id,
              response: value.toString(),
              question: {
                id: questionUsed.id,
                text: questionUsed.text,
                type: EvaluationQuestionType.STUDENT,
                section: EvaluationQuestionSection.OPPORTUNITY,
                order: questionUsed.order
              }
            }
            newEvaluations.push(newEval)
          }
        }
      });

      if(updatedEvaluations.length > 0) {
        updatedEvaluations.forEach(evaluation => {
          this.serviceRegistrationService.updateEvaluations(this.service.id, evaluation).pipe(take(1)).subscribe();
        });
      }

      if(newEvaluations.length > 0) {
        this.serviceRegistrationService.addEvaluations(this.service.id, newEvaluations).pipe(take(1)).subscribe();
      }

    } else { //No saved evaluations, then save all selected options
      const newEvaluations: Partial<IEvaluation>[] = Object.keys(this.opportunityForm.controls)
        .filter(question => {
          const value = this.opportunityForm.get(question)?.value;
          return !(value === null || value === undefined || value === false);
        })
        .map((question, index) => {
        const value = this.opportunityForm.get(question)?.value;
        const questionUsed = this.evaluationQuestions.find(evalQuestion => evalQuestion.id === question)!;

        return {
          serviceId: this.service.id,
          response: value.toString(),
          question: {
            id: questionUsed.id,
            text: questionUsed.text,
            type: EvaluationQuestionType.STUDENT,
            section: EvaluationQuestionSection.OPPORTUNITY,
            order: questionUsed.order
          }
        };
      });

      this.serviceRegistrationService.addEvaluations(this.service.id, newEvaluations).pipe(take(1)).subscribe();
    }
  }
}
