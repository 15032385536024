<div class="likert-table f-col">
  <div class="title-and-desc f-col">
    <div class="medium-bold-text">Performance</div>
    <div class="small-reg-text">Please rate the student’s performance in the following areas:</div>
  </div>
  <table class="performance-table" [formGroup]="performanceForm">
    <legend class="f-col">
      <div class="legend-row xx-small-medium-text">
        <div>Poor</div>
        <div>Fair</div>
        <div>Acceptable</div>
        <div>Good</div>
        <div>Excellent</div>
      </div>
    </legend>
    <tr *ngFor="let question of evaluationQuestions">
      <div class="small-bold-text">{{ question.text }}</div>
      <ul class="f-row">
        <li class="custom-radio-input" *ngFor="let value of ['Poor', 'Fair', 'Acceptable', 'Good', 'Excellent']">
          <input type="radio" [formControlName]="question.text" [value]="value" />
        </li>
      </ul>
    </tr>
  </table>
</div>
