<div class="medium-regular-text">What grade has the student earned?</div>
<form [formGroup]="gradeForm" class="radio-group f-col">
  <div class="custom-input custom-radio-input" [ngClass]="gradeForm.get('grade')?.value === ServiceGrade.A ? 'active' : ''">
    <input type="radio"  id="a" name="grade" [value]="ServiceGrade.A"  formControlName="grade">
    <label for="a" class="small-reg-text">A — Exceptional service and attitude</label>
  </div>
  <div class="custom-input custom-radio-input" [ngClass]="gradeForm.get('grade')?.value === ServiceGrade.B ? 'active' : ''">
    <input type="radio"  id="b" name="grade" [value]="ServiceGrade.B"  formControlName="grade">
    <label for="b" class="small-reg-text">B — Good service and attitude</label>
  </div>
  <div class="custom-input custom-radio-input" [ngClass]="gradeForm.get('grade')?.value === ServiceGrade.C ? 'active' : ''">
    <input type="radio"  id="c" name="grade" [value]="ServiceGrade.C" formControlName="grade">
    <label for="c" class="small-reg-text">C — Acceptable service and attitude</label>
  </div>
  <div class="custom-input custom-radio-input" [ngClass]="gradeForm.get('grade')?.value === ServiceGrade.D ? 'active' : ''">
    <input type="radio"  id="d" name="grade" [value]="ServiceGrade.D"  formControlName="grade">
    <label for="d" class="small-reg-text">D — Poor service and attitude</label>
  </div>
  <div class="custom-input custom-radio-input" [ngClass]="gradeForm.get('grade')?.value === ServiceGrade.F ? 'active' : ''">
    <input type="radio"  id="f" name="grade" [value]="ServiceGrade.F"  formControlName="grade">
    <label for="f" class="small-reg-text">F — Unacceptable service or attitude</label>
  </div>
</form>
