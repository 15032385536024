import {Component} from '@angular/core';

@Component({
  selector: 'app-past-evaluation-review',
  templateUrl: './past-evaluation-review.component.html',
  styleUrls: ['./past-evaluation-review.component.scss']
})
export class PastEvaluationReviewComponent {

}
