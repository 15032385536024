<div class="title-and-desc f-col">
  <div class="large-bold-text">Student's Hours Logged</div>
  <div class="small-reg-text">Confirm that the following hours logged by the student are accurate.</div>
</div>

<app-log-time-widget *ngIf="!!service" [serviceRegistration]="service" [isEvaluationReview]="true" [isReadOnly]="true" ></app-log-time-widget>

<form [formGroup]="form" class="question f-col">
  <div class="medium-regular-text">Do you verify that the hours shown are a complete and accurate representation of the work done by the student?</div>
  <div class="answers f-row gap-5">
    <div class="custom-input custom-radio-input" [ngClass]="form.get('Answer')?.value === Answer.YES ? 'radio-active' : ''">
      <input type="radio" id="Yes" name="Answer" value="Yes" [formControlName]="'Answer'" >
      <label for="Yes" class="small-reg-text ps-2" for="Yes">Yes</label>
    </div>
    <div class="custom-input custom-radio-input" [ngClass]="form.get('Answer')?.value === Answer.NO ? 'radio-active' : ''">
      <input type="radio" id="No" name="Answer" value="No" [formControlName]="'Answer'">
      <label for="No" class="small-reg-text ps-2" for="No">No, there is an issue with one or more items</label>
    </div>
  </div>
  <div *ngIf="form.get('Answer')?.value === Answer.NO" class="issue-wrapper">
    <label for="Issues" id="IssuesLabel" class="small-reg-text">Explain which entries have issues and why</label>
    <textarea id="Issues" class="custom-input text-area"  [formControlName]="'Issues'" aria-labelledby="IssuesLabel"></textarea>
  </div>
</form>
