import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {map, Subject, takeUntil} from 'rxjs';
import {ServiceRegistrationService} from '../../../../core/service/service-registration.service';
import {
  EvaluationQuestion,
  EvaluationQuestionSection,
  EvaluationQuestionType,
  IEvaluation
} from '../../../../shared/model/evaluation-model';
import {IServiceRegistration} from '../../../../shared/model/service-registration.model';

interface FormControls {
  [key: string]: [any, ValidatorFn[]];
}

@Component({
  selector: 'app-service-evaluation-future',
  templateUrl: './service-evaluation-future.component.html',
  styleUrls: ['./service-evaluation-future.component.scss']
})
export class ServiceEvaluationFutureComponent implements OnInit, OnDestroy {
  @Input()
  public service!: IServiceRegistration;
  public futureForm!: FormGroup;
  public formControls: FormControls = {};
  public evaluations: IEvaluation[] = [];

  @Output()
  public evaluationComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  public evaluationQuestions: EvaluationQuestion[] = [
    { id: 'SU_FUT_Q1', text: 'Future Volunteer Opportunity', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.FUTURE, order: '1' },
    { id: 'SU_FUT_Q2', text: 'Letter of Recommendation', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.FUTURE, order: '2' },
    { id: 'SU_FUT_Q3', text: 'Reference on Resumé', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.FUTURE, order: '3' }
  ];

  private endSubscription$ = new Subject();


  constructor(private fb: FormBuilder, private serviceRegistrationService: ServiceRegistrationService) {}

  ngOnInit() {
    this.evaluationQuestions.forEach(question => {
      this.formControls[question.text] = [null, [Validators.required]];
    });

    this.futureForm = this.fb.group(this.formControls);

    this.getEvaluations();

    this.futureForm.valueChanges.pipe(takeUntil(this.endSubscription$)).subscribe(() => {
      this.evaluationComplete.emit(this.futureForm.valid);
    })
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  public getEvaluations() {
    this.serviceRegistrationService.getEvaluations(this.service.id).pipe(
      map(evaluations => evaluations.filter(evaluation => evaluation.question.type === EvaluationQuestionType.SUPERVISOR && evaluation.question.section === EvaluationQuestionSection.FUTURE)))
      .subscribe(filteredEvals => {
        this.evaluations = filteredEvals;

        filteredEvals.forEach(evaluation => {
          if(evaluation.question.order === '1') {
            this.futureForm.get('Future Volunteer Opportunity')?.setValue(evaluation.response);
          } else if (evaluation.question.order === '2') {
            this.futureForm.get('Letter of Recommendation')?.setValue(evaluation.response);
          } else if (evaluation.question.order === '3') {
            this.futureForm.get('Reference on Resumé')?.setValue(evaluation.response);
          }
        })
      })
  }

  public submitEvaluations() {
    if (this.evaluations.length > 0) {
      const updatedEvaluations: IEvaluation[] = [];

      Object.keys(this.futureForm.controls).forEach((question, index) => {
        const value = this.futureForm.get(question)?.value;
        const existingEvaluation = this.evaluations.find(evaluation => evaluation.question.order === this.evaluationQuestions[index].order);

        if (existingEvaluation) {
          existingEvaluation.response = value;
          updatedEvaluations.push(existingEvaluation);
        }
      });

      updatedEvaluations.forEach(evaluation => {
        this.serviceRegistrationService.updateEvaluations(this.service.id, evaluation).subscribe();
      });
    } else {
      const newEvaluations: Partial<IEvaluation>[] = Object.keys(this.futureForm.controls).map((question, index) => {
        const value = this.futureForm.get(question)?.value;

        return {
          serviceId: this.service.id,
          response: value,
          question: {
            id: this.evaluationQuestions[index].id,
            order: this.evaluationQuestions[index].order,
            section: EvaluationQuestionSection.FUTURE,
            text: question,
            type: EvaluationQuestionType.SUPERVISOR
          }
        };
      });

      this.serviceRegistrationService.addEvaluations(this.service.id, newEvaluations).subscribe();
    }
  }

}
