import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {map, Subject} from 'rxjs';
import {ServiceRegistrationService} from '../../../../core/service/service-registration.service';
import {
  EvaluationQuestion,
  EvaluationQuestionSection,
  EvaluationQuestionType, IEvaluation
} from '../../../../shared/model/evaluation-model';
import {IServiceRegistration, ServiceGrade} from '../../../../shared/model/service-registration.model';

@Component({
  selector: 'app-service-evaluation-grade',
  templateUrl: './service-evaluation-grade.component.html',
  styleUrls: ['./service-evaluation-grade.component.scss']
})
export class ServiceEvaluationGradeComponent implements OnInit, OnDestroy {

  @Input()
  public service!: IServiceRegistration;

  @Output()
  public evaluationComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public gradeEvent: EventEmitter<ServiceGrade> = new EventEmitter<ServiceGrade>();
  public gradeForm!: FormGroup;
  public evaluations: IEvaluation[] = [];
  private endSubscription$ = new Subject();

  public evaluationQuestions: EvaluationQuestion[] = [
    { id: 'SU_GRA_1', text: 'Grade', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.GRADE, order: '1' }]

    constructor(private fb: FormBuilder, private serviceRegistrationService: ServiceRegistrationService) {}

  ngOnInit() {
    this.gradeForm = this.fb.group({
      grade: [null, Validators.required]
    });

    this.getEvaluations();

    this.gradeForm.valueChanges.subscribe(value => {
      this.evaluationComplete.emit(this.gradeForm.valid);
      this.gradeEvent.emit(value);
    })
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  public getEvaluations() {
    this.serviceRegistrationService.getEvaluations(this.service.id).pipe(
      map(evaluations => evaluations.filter(evaluation => evaluation.question.type === EvaluationQuestionType.SUPERVISOR && evaluation.question.section === EvaluationQuestionSection.GRADE)))
      .subscribe(filteredEvals => {
        this.evaluations = filteredEvals;

        filteredEvals.forEach(evaluation => {
          if(evaluation.question.order === '1') {
            this.gradeForm.get('grade')?.setValue(evaluation.response);
          }
        })
      })
  }

  public submitEvaluations() {
    if (this.evaluations.length > 0) {
      const updatedEvaluations: IEvaluation[] = [];

      Object.keys(this.gradeForm.controls).forEach((question, index) => {
        const value = this.gradeForm.get(question)?.value;
        const existingEvaluation = this.evaluations.find(evaluation => evaluation.question.order === this.evaluationQuestions[index].order);

        if (existingEvaluation) {
          existingEvaluation.response = value;
          updatedEvaluations.push(existingEvaluation);
        }
      });

      updatedEvaluations.forEach(evaluation => {
         this.serviceRegistrationService.updateEvaluations(this.service.id, evaluation).subscribe();
      });
    } else {
      const newEvaluations: Partial<IEvaluation>[] = Object.keys(this.gradeForm.controls).map((question, index) => {
        const value = this.gradeForm.get(question)?.value;

        return {
          serviceId: this.service.id,
          response: value,
          question: {
            id: this.evaluationQuestions[index].id,
            order: this.evaluationQuestions[index].order,
            section: EvaluationQuestionSection.GRADE,
            text: question,
            type: EvaluationQuestionType.SUPERVISOR
          }
        };
      });

     this.serviceRegistrationService.addEvaluations(this.service.id, newEvaluations).subscribe();
    }
  }

  protected readonly ServiceGrade = ServiceGrade;
}
