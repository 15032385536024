<div class="medium-bold-text">Why did you choose this CSER Opportunity?</div>
<div class="actions f-col">
  <div class="small-medium-text">Check all that apply:</div>
  <form [formGroup]="opportunityForm" class="check-list f-col">

    <div *ngFor="let question of evaluationQuestions" class="check-box">
      <input *ngIf="question.id !== 'ST_OPP_13'" type="checkbox" [id]="question.id" [formControlName]="question.id">
      <label *ngIf="question.id !== 'ST_OPP_13'" class="small-medium-text" [for]="question.id">{{question.text}}</label>
    </div>
    <div *ngIf="opportunityForm.get('ST_OPP_12')?.value === true">
      <input class="custom-input" type="text" [formControlName]="'ST_OPP_13'">
    </div>
  </form>
</div>
