<div class="header-wrapper" >
  <div class="header-info f-col" *ngIf="service">
    <a class="back-link" role="tab" [routerLink]="['/', 'supervisor', 'my-opportunities', service.oppId, 'active-volunteers']" aria-current="page">
      <app-icon icon="back-arrow" class="back-icon"></app-icon>
      <div class="x-small-medium-text">Back to Dashboard</div>
    </a>
    <div class="title-and-name f-col">
      <div class="x-large-x-bold-text">Service Evaluation</div>
      <div class="name-and-term">
        <div class="medium-bold-text">{{service.studentName}}</div>
        <div class="medium-regular-text">{{service.term | term}}</div>
      </div>
    </div>
  </div>
  <mat-stepper class="stepper f-row" [linear]="true" labelPosition="bottom" color="primary" #stepper>
    <mat-step label="Hours" [completed]="canProceedStepper(0)" ></mat-step>
    <mat-step label="Performance" [completed]="canProceedStepper(1)" ></mat-step>
    <mat-step label="Future" [completed]="canProceedStepper(2)"></mat-step>
    <mat-step label="Improvement" [completed]="canProceedStepper(3)"></mat-step>
    <mat-step label="Grade" [completed]="canProceedStepper(4)"></mat-step>
  </mat-stepper>
</div>

<div [ngSwitch]="stepper.selected?.label" class="switch-page f-col">
  <div *ngSwitchCase="'Hours'">
    <app-service-evaluation-hours *ngIf="service" [service]="service" (evaluationComplete)="evalEvent($event, 'hours')" (correctionEvent)="evalHoursEvent($event)" class="f-col"  #hoursComponent></app-service-evaluation-hours>
  </div>
  <div *ngSwitchCase="'Performance'">
    <app-service-evaluation-performance *ngIf="service" [service]="service" class="f-col" (evaluationComplete)="evalEvent($event, 'performance')" #performanceComponent></app-service-evaluation-performance>
  </div>
  <div *ngSwitchCase="'Future'">
    <app-service-evaluation-future *ngIf="service" [service]="service" (evaluationComplete)="evalEvent($event, 'future')" class="f-col" #futureComponent></app-service-evaluation-future>
  </div>
  <div *ngSwitchCase="'Improvement'">
    <app-service-evaluation-improvement *ngIf="service" [service]="service" (evaluationComplete)="evalEvent($event, 'improvement')" class="f-col" #improvementComponent></app-service-evaluation-improvement>
  </div>
  <div *ngSwitchCase="'Grade'">
    <app-service-evaluation-grade class="f-col" *ngIf="service" [service]="service" (gradeEvent)="setGrade($event)" (evaluationComplete)="evalEvent($event, 'grade')" #gradeComponent></app-service-evaluation-grade>
  </div>
</div>

<div class="buttons-wrapper f-row">
  <button *ngIf="stepper.selected?.label != 'Hours'" class="custom-button-large-secondary" (click)="stepper.previous()">
    <app-icon icon="arrow-left" class="left-icon"></app-icon>
    Previous
  </button>
  <div *ngIf="stepper.selected?.label == 'Hours'"></div>
  <button class="custom-button-large-primary-dark-blue" *ngIf="stepper.selected?.label != 'Grade'"  [disabled]="!canProceedStepper(stepper.selectedIndex)" (click)="stepper.next(); submitAnswers(stepper.selectedIndex)">
    Continue
    <app-icon icon="arrow-right" class="right-icon"></app-icon>
  </button>
  <button class="custom-button-large-primary-dark-blue" *ngIf="stepper.selected?.label == 'Grade'" [disabled]="!canProceedStepper(stepper.selectedIndex) || (isRegistering$ | async)" (click)="submitEvaluation()">
   Submit
  </button>
</div>
<app-spinner *ngIf="isRegistering$ | async"></app-spinner>
